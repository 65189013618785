import Auth from '@/config/Auth';

export default {
  name: 'Logout',
  methods: {
    logout() {
      Auth.logOut();
    }
  },
  mounted() {
    this.logout();
  }
};
